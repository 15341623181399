@import '~react-quill/dist/quill.snow.css';


.ql-editor {
    min-height: 28em;
    background: #fff;
    padding: 20px;
    font-size: 20px;
    max-height: 50em;
  }

.ql-snow.ql-toolbar {
    display: flex;
    justify-content: center;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }


  